import React, { useMemo } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

import { createAccountView, ViewComponent } from "@account/view";
import CompanySwitch from "~/components/CompanySwitch/CompanySwitch";
import { useAuth } from "@api/auth";
import { Typography } from "~/components/Typography";

const useStyles = makeStyles((theme) => {
	return {
		content: {
			padding: 12,
			maxWidth: 480,
		},
	};
});

const Company: ViewComponent = () => {
	const { user, companyAdmin } = useAuth();
	const isAdmin = user?.autocorpAdmin || companyAdmin;
	const styles = useStyles();

	const content = useMemo(() => {
		if (isAdmin) {
			return <CompanySwitch />;
		}
		return (
			<Typography variant="caption">
				This functionality is only available for company admin
			</Typography>
		);
	}, [isAdmin]);

	return <Box className={styles.content}>{content}</Box>;
};

export default createAccountView(Company, {});
