import React from "react";

import { useAuth } from "@api/auth";

import { LayoutComponent } from "~/model/layout";

import TabbedNav from "~/components/TabbebNav";

export const AccountLayout: LayoutComponent<never> = () => {
	const { user, companyAdmin } = useAuth();

	const isAdmin = user?.autocorpAdmin || companyAdmin;

	return (
		<TabbedNav
			tabs={[
				{
					title: "Personal Information",
					path: "/account/personal",
				},
				{
					title: "Company",
					path: "/account/company",
					disabled: !isAdmin,
				},
			]}
		/>
	);
};
