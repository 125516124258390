import React from "react";
import { makeStyles, lighten } from "@material-ui/core/styles";
import clsx from "clsx";

import { slash } from "@utils/url";
import { Typography } from "~/components/Typography";
import { PrivatePaths } from "~/model/paths";

import { Link } from "~/components/Link";

import type { ITabProps } from "./types";

import { brandingColor } from "~/styles/colors";

const useStyles = makeStyles(({ palette }) => {
	return {
		title: {
			color: palette.primary.main,
			textTransform: "none",
			textDecorationLine: "none",
			padding: "0 20px 8px",
			marginBottom: -1,
			"&:hover:not(.selected)": {
				borderBottom: `4px solid ${lighten(
					palette.secondary.main,
					0.75,
				)}`,
			},
			"&.selected": {
				borderBottom: `4px solid ${brandingColor}`,
			},
			"&.disabled": {
				opacity: 0.3,
				cursor: "default",
			},
		},
		selectedTitle: {
			fontWeight: "bold",
			color: `${brandingColor}`,
		},
	};
});

const checkSelected = (curPath: string, checkPath: PrivatePaths) =>
	curPath === slash(checkPath);

const Tab: React.FC<ITabProps> = ({
	title,
	path,
	location,
	selected,
	disabled,
	...linkProps
}) => {
	const isSelected = selected ?? checkSelected(location.pathname || "", path);
	const styles = useStyles();

	return (
		<Link
			className={clsx(
				styles.title,
				isSelected && "selected",
				disabled && "disabled",
			)}
			{...linkProps}
			to={!disabled ? path : ("#" as PrivatePaths)}
		>
			<Typography
				variant="body1"
				className={clsx(isSelected && styles.selectedTitle)}
			>
				{title}
			</Typography>
		</Link>
	);
};

export default Tab;
