import { createPrivateView, ViewComponent } from "~/model/view/private";
import { AccountLayout } from "@account/AccountLayout";

export const createAccountView: typeof createPrivateView = (
	component,
	props = {},
) => {
	return createPrivateView(component, {
		...props,
		showBack: false,
		title: "Account",
		layoutProps: {
			topNav: {
				sub: AccountLayout,
			},
		},
	});
};

export type { ViewComponent };
